import React from 'react'
import { Container } from '@material-ui/core';
import LoSentimosCard from '../common/LoSentimosCard'

function MuchasGraciasManager({ companyName }) {

    const dataInfo = {
        "textHeader": "Muchas gracias por tu dictamen",
        "textBox": "Tu decisión es muy importante para Grupo Findep y para el éxito del producto de Colaboradores.",
        "textBoxTwo": " ",
    }

    return (
        <Container justify="center">
            <LoSentimosCard data={dataInfo} companyName={companyName} />
        </Container>

    )
}

export default MuchasGraciasManager

