import React from "react"
import SEO from "../components/seo"
import Layout from '../layouts/findep'
import LoSentimos from '../components/pages/MuchasGraciasManager'
const IndexPage = ({pageContext}) => (
  <Layout companyName={pageContext.company} >
    <SEO title={pageContext.title} />
    <LoSentimos companyName={pageContext.company} />
  </Layout>
)

export default IndexPage